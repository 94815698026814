
function Experiences(){
    let button = "absolute top-0 left-1/2 -translate-x-1/2 w-4 h-4 rounded-full bg-[var(--secondary-color)]";
    let line = " relative bg-[var(--secondary-color)]";
    let lineLast = 'relative ';
    let tech = 'uppercase bg-[var(--secondary-color)] p-1 px-4 rounded-lg border-2 border-[var(--primary-color)]';
    let companyName = 'font-semibold max-w-[75%]';
    let textStyle ='flex lg:text-xl text-lg justify-between'
    let timeLine = <div className={line}>
        <div className={button}>

        </div>
    </div>;
    return (
        <div className={`my-0 lg:pl-20  max-w-[900px] w-full lg:pr-16 p-[25px] grid`} style={{
            gridTemplateColumns:"5px 4fr"
        }}>
            {/* CISCO MERAKI */}
            {timeLine}
            <div className='m-4 ml-8 -translate-y-5 '>
                <div className={textStyle}>
                    <span className={companyName}>
                        Cisco Meraki
                    </span>
                        <span className='pl-4 float-end  poppins-regular text-[1rem] '>
                        Sep 2024 - Dec 2024
                    </span>
                </div>
                <div className='clear-both'>
                    Software Engineering Intern
                </div>
                <div className='flex gap-2 py-2 text-sm flex-wrap'>
                    <div className={tech}>React Native</div>
                    <div className={tech}>Full Stack</div>
                </div>
                <ul className='list-disc px-4 py-2'>
                    <li className='py-1'>
                        Developing mobile interface for Secure Connect team in Meraki Dashboard using React Native
                    </li>
                </ul>

            </div>

            {/* SOMOS */}
            {timeLine}
            <div className='m-4 ml-8 -translate-y-5 '>
                <div className={textStyle}>
                    <span className={companyName}>
                        Somos Inc.
                    </span>
                    <span className='pl-4 float-end  poppins-regular text-[1rem] '>
                        May 2024 - Aug 2024
                    </span>
                </div>
                <div className='clear-both'>
                    Software Engineering Intern
                </div>
                <div className='flex gap-2 py-2 text-sm flex-wrap'>
                    <div className={tech}>AWS</div>
                    <div className={tech}>Serverless</div>
                    <div className={tech}>Cloud Computing</div>
                    <div className={tech}>Agile</div>
                </div>
                <ul className='list-disc px-4 py-2'>
                    <li className='py-1'>
                        Worked on the RealBrand product, utilizing a microservices architecture and cloud computing
                        technologies.
                    </li>
                    <li className='py-1'>
                        Implemented both front-end and back-end functionalities using TypeScript and React, along with
                        JUnit test cases for ensuring code quality.
                    </li>
                    <li className='py-1'>
                        Developed and deployed a suite of AWS services, including Lambda, API Gateway, DynamoDB, SQS,
                        and SNS, to interconnect microservices, transform data, and expose it for customer use.
                    </li>
                    <li className='py-1'>
                        Created documentation for programming/UI design standards used by over 20 developers/designers
                    </li>
                </ul>
            </div>

            {/*PURDUE UNIVERSITY*/}
            {timeLine}

            <div className='m-4 ml-8 -translate-y-5 '>
                <div className={textStyle}>
                    <span className={companyName}>
                        Purdue University
                    </span>
                    <span className='pl-4 float-end  poppins-regular text-[1rem] '>
                        Aug 2022 – May 2023
                    </span>
                </div>
                <div className='clear-both'>
                    Undergraduate Data Science Researcher
                </div>
                <div className='flex gap-2 py-2 text-sm flex-wrap'>
                    <div className={tech}>Data Analysis</div>
                    <div className={tech}>Python</div>
                    <div className={tech}>R</div>
                    <div className={tech}>SQL</div>
                    <div className={tech}>RestAPI</div>
                    <div className={tech}>Statistics</div>
                </div>
                <ul className='list-disc px-4 py-2'>
                    <li className='py-1'>
                        Developed a data pipeline using WHIM Rest API, R, Python, and SQL to store data from Weather
                        systems.
                    </li>
                    <li className='py-1'>
                        Manipulated South American moisture sensor data from WeBee to craft an accurate moisture
                        prediction model in order to save freshwater in agricultural sector.
                    </li>
                </ul>
            </div>

            {/*UNIVERSITY OF CHICAGO*/}
            <div className={lineLast}>
                <div className={button}>

                </div>
            </div>
            <div className='m-4 ml-8 -translate-y-5 '>
                <div className={textStyle}>
                    <span className={companyName}>
                        University of Chicago
                    </span>
                        <span className='pl-4 float-end  poppins-regular text-[1rem] '>
                        Oct 2021 – Jan 2022
                    </span>
                </div>
                <div className='clear-both'>
                    University Research Assistant, Backend Software Developer
                </div>
                <div className='flex gap-2 py-2 text-sm flex-wrap'>
                    <div className={tech}>Node.js</div>
                    <div className={tech}>HTML/css</div>
                    <div className={tech}>Javascript</div>
                    <div className={tech}>RestAPI</div>
                    <div className={tech}>Firebase</div>
                </div>
                <ul className='list-disc px-4 py-2'>
                    <li className='py-1'>
                        Worked under Noelle Huang with Professor John List’s team at the Kenneth C. Griffin Department
                        of Economics to provide algorithmic solutions to improve their research data gathering process.
                    </li>
                    <li className='py-1'>
                        Created and implemented backend architecture, integration and documentation for team's data
                        collection needs using Ease Attendance and various other microservices.
                    </li>
                    <li className='py-1'>
                        Designed and implemented a customized responsive dashboard to view data during study.
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Experiences