import './css/App.css';
import './css/poppins.css';
import './css/loader.css';
import Uneditable from "./Components/Uneditable";
import Socials from "./Components/Socials";
import {useEffect, useRef} from "react";
import "firebase/firestore";
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import MyImage from "./Components/MyImage";
import Links from "./Components/Links";
import Experiences from "./Components/Experiences";
import About from "./Components/About";
import DarkMode from "./Components/DarkMode";
import React from 'react';
import Projects from "./Components/Projects";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyCo8yLms5C30aop8gg3XJYjPISz5osU8A4",
    authDomain: "adityapatelnet-58a1a.firebaseapp.com",
    projectId: "adityapatelnet-58a1a",
    storageBucket: "adityapatelnet-58a1a.appspot.com",
    messagingSenderId: "794469216589",
    appId: "1:794469216589:web:059a41bcf956f47df7c704",
    measurementId: "G-3JX7GDWHWN"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'idkAnEvent', {
    content_type: 'init',
    content_id: 'adi is nice'
});

function App() {
    const loader = useRef(null);
    useEffect(() => {
        loader.current.style.transition = "opacity 0.25s";
        setTimeout(()=>{
            loader.current.style.opacity = "0%";

        },750)
        setTimeout(() => {
            loader.current.style.display = 'none';
        }, 1000)
    }, [])
    const currentYear = new Date().getFullYear();
    if (localStorage.getItem('mode') === null) {
        localStorage.setItem('mode', 'dark');
    } else if (localStorage.getItem('mode') === 'light') {
        document.documentElement.style.setProperty('--background-color', "white");
        document.documentElement.style.setProperty('--text-color', "#383837");

        document.documentElement.style.setProperty('--primary-color', "#8d8f84");
        document.documentElement.style.setProperty('--secondary-color', "#e0e0df");
    }

    let switchMode = () => {
        if (localStorage.getItem('mode') === "dark") {
            localStorage.setItem('mode', 'light');
            document.documentElement.style.setProperty('--background-color', "white");
            document.documentElement.style.setProperty('--text-color', "#1e1e1d");

            document.documentElement.style.setProperty('--primary-color', "#8d8f84");
            document.documentElement.style.setProperty('--secondary-color', "#e0e0df");

        } else {
            localStorage.setItem('mode', 'dark');
            document.documentElement.style.setProperty('--background-color', "#1e1e1d");
            document.documentElement.style.setProperty('--text-color', "white");

            document.documentElement.style.setProperty('--primary-color', "#8d8f84");
            document.documentElement.style.setProperty('--secondary-color', "#494543");
        }
    }

    return (
        <div className='poppins-regular'>
            <div ref={loader} className="loader-wrapper w-screen h-screen">
                <span className="loader"><span className="loader-inner"></span></span>
            </div>
            <div className='absolute top-0 right-0' onClick={switchMode}>
                <DarkMode/>
            </div>
            <Socials/>
            <div className='outerDiv flex'>
                <div id='main' className='w-9/12 min-h-[100%] flex lg:flex-row justify-center flex-col-reverse items-center'>
                    <div className='intro flex flex-col text-center'>
                        <Uneditable inputValue="Aditya Patel"/>
                        <h2 className='text-lg'>
                            Incoming SWE Intern @ Cisco Meraki 💼 <br/>
                            Computer Science @ Purdue University 📚
                        </h2>
                        <Links/>
                    </div>
                    <div className='flex justify-center'>
                        <MyImage/>
                    </div>
                </div>
            </div>
            <div className='w-screen py-[5vh] lg:px-[12%]'>
                <h2 id='about' className='text-center lg:text-4xl text-3xl poppins-bold py-10'>About</h2>
                <div className='flex w-full h-fit'>
                    <About/>
                </div>
            </div>
            <div className='w-screen x- pt-[5vh] lg:px-[12%]' >
                <h2 id='experience' className='text-center lg:text-4xl text-3xl poppins-bold py-10'>Experience</h2>
                <div className='flex justify-center w-full h-fit lg:pl-[0] pl-[5%]'>
                    <Experiences/>
                </div>
            </div>
            <div className='w-screen py-[5vh] px-[12%]'>
                <h2 id='projects' className='text-center lg:text-4xl text-3xl poppins-bold py-10'>Projects</h2>
                <div className='w-full h-fit'>
                    <Projects/>
                </div>
            </div>

            {/*<div className='w-screen min-h-screen py-[5vh] px-[12%]'>*/}
            {/*    <h2 id='interests' className='text-center lg:text-4xl text-3xl poppins-bold py-10'>Interests</h2>*/}
            {/*    <div className='flex w-full h-fit'>*/}

            {/*    </div>*/}
            {/*</div>*/}
            <footer className='text-center py-8'>
                <p>&copy; {currentYear} Aditya Patel. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default App;
