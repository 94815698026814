import loader from '../assets/loader.png'
import React, {useRef,useState, useEffect} from "react";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

function MyImage () {

    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [text, setText] = useState(null);

    useEffect(() => {
        let array =['Software Development',  'Cloud Computing', 'AI and Machine Learning', 'Computer Science', 'economics & finance', ''];

        let growing = false;
        let arrayIndex = 0;
        let timeoutID = 0;
        let curIndex = array[0].length - 1;

        const rotateText = () => {
            if (curIndex === 0 && growing === false) { //stopped cutting
                growing = true;
                if (arrayIndex === array.length-1) {
                    arrayIndex = 0;
                } else {
                    arrayIndex++;
                }
                timeoutID = setTimeout(rotateText, 1000)
            } else if (curIndex === array[arrayIndex].length && growing === true){ //stop growing
                growing = false;
                timeoutID = setTimeout(rotateText, 2000)
            }else if (growing === true) { // grow
                setText(array[arrayIndex].substring(0,curIndex+1))
                curIndex++;
                timeoutID = setTimeout(rotateText, 50)
            } else { //cut
                setText(array[arrayIndex].substring(0, curIndex - 1))
                curIndex--;
                timeoutID = setTimeout(rotateText, 50)
            }
        }
        rotateText()
        return () => {
            clearTimeout(timeoutID)
        };
    }, [])


    useEffect(() => {
        const handleImageLoad = () => {
            if (imageRef.current) {
                setImageDimensions({
                    width: imageRef.current.offsetWidth,
                    height: imageRef.current.offsetHeight,
                });
            }
        };
        if (imageRef.current.complete) {
            handleImageLoad();
        } else {
            imageRef.current.onload = handleImageLoad;
        }
        //window.addEventListener('resize', handleImageLoad);

        const resizeObserver = new ResizeObserver( (entries) => {
            handleImageLoad()
        })
        resizeObserver.observe(imageRef.current)
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const storage = getStorage();
        getDownloadURL(ref(storage, 'ishaDoesntLike.png'))
            .then((url) => {
                // `url` is the download URL for 'images/stars.jpg'
                // Or inserted into an <img> element
                imageRef.current.src = url
                console.log(url)
            })
            .catch((error) => {
                // Handle any errors
                console.log(error)
            });
    }, [])

    useEffect(() => {
        if (div1Ref.current) {
            div1Ref.current.style.width = `${imageDimensions.width}px`;
            div1Ref.current.style.height = `${imageDimensions.height}px`;
            div1Ref.current.style.transform = `translate(${imageDimensions.height/20}px, ${imageDimensions.width/20}px)`;
        }
        if (div2Ref.current) {
            div2Ref.current.style.width = `${imageDimensions.width}px`;
            div2Ref.current.style.height = `${imageDimensions.height}px`;
            div2Ref.current.style.transform = `translate(-${imageDimensions.height/20}px, -${imageDimensions.width/20}px)`;
        }
        if (textRef.current) {
            textRef.current.style.width = `${imageDimensions.width * 1.5}px`;
            textRef.current.style.transform = `translate(0,${imageDimensions.height/20}px)`;
        }
    }, [imageDimensions]);
    return (
        <div style={{
            display:"inline-block",
        }} className='py-20 relative'>
                <div ref={div1Ref} style={{
                    backgroundColor: 'var(--primary-color)',
                    position: 'absolute',
                    transform: `translate(15px, 10px)`,
                    zIndex:'-1',
                    left:"25%"

                }} className='mx-auto'
                ></div>
                <div ref={div2Ref} style={{
                    backgroundColor: 'var(--secondary-color)',
                    position: 'absolute',
                    transform: `translate(-15px, -10px)`,
                    zIndex:'-1',
                    left:"25%"
                }} className='mx-auto'></div>
            <img ref={imageRef} loading='lazy' className='block z-40 w-1/2 mx-auto' src={loader} alt="A man with glasses looking to the side of the camera."/>

            <h2 ref={textRef} className='mx-auto text-center font-bold lg:text-lg h-0'>Developer w/ interest in {text}</h2>
        </div>
    );
}
export default MyImage;