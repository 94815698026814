function Socials() {
    return (
        <div className="fixed inset-y-[40%] lg:ml-6 ml-2">
            <a target="__blank" className="button lg:text-4xl text-3xl cursor-pointer mb-2"
               href="https://github.com/adityapatel-sudo">
                <svg className='hover:fill-[var(--primary-color)] social' stroke="currentColor" fill="currentColor"
                     strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.976 0A7.977 7.977 0 0 0 0 7.976c0 3.522 2.3 6.507 5.431 7.584.392.049.538-.196.538-.392v-1.37c-2.201.49-2.69-1.076-2.69-1.076-.343-.93-.881-1.175-.881-1.175-.734-.489.048-.489.048-.489.783.049 1.224.832 1.224.832.734 1.223 1.859.88 2.3.685.048-.538.293-.88.489-1.076-1.762-.196-3.621-.881-3.621-3.964 0-.88.293-1.566.832-2.153-.05-.147-.343-.978.098-2.055 0 0 .685-.196 2.201.832.636-.196 1.322-.245 2.007-.245s1.37.098 2.006.245c1.517-1.027 2.202-.832 2.202-.832.44 1.077.146 1.908.097 2.104a3.16 3.16 0 0 1 .832 2.153c0 3.083-1.86 3.719-3.62 3.915.293.244.538.733.538 1.467v2.202c0 .196.146.44.538.392A7.984 7.984 0 0 0 16 7.976C15.951 3.572 12.38 0 7.976 0z">
                    </path>
                </svg>
                <div className=""></div>
            </a>
            <a target="__blank" className="button lg:text-4xl text-3xl cursor-pointer mb-2"
               href="https://www.linkedin.com/in/adityapatel123/">
                <svg className='hover:fill-[var(--primary-color)] social' stroke="currentColor" fill="currentColor"
                     strokeWidth="0" viewBox="0 0 448 512" height="1em"
                     width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                </svg>
            </a>
            <a target="__blank" className="button lg:text-4xl text-3xl cursor-pointer mb-2"
               href="https://x.com/aditpate">
                <svg className='hover:fill-[var(--primary-color)] social' stroke="currentColor" fill="currentColor"
                     strokeWidth="0" height="1em"
                     width="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path
                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15"/>
                </svg>
            </a>
            <a target="__blank" className="button lg:text-4xl text-3xl cursor-pointer mb-2"
               href="mailto:adityapatel1204+web@gmail.com">
                <svg className='hover:stroke-[var(--primary-color)] social' stroke="currentColor" fill="none"
                     strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true"
                     height="1em"
                     width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path>
                </svg>
            </a>
        </div>
    );
}

export default Socials;