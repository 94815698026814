function Links(){

    const redirect = () => {
        const currentDomain = window.location.origin;
        window.location.href = `${currentDomain}/AdityaPatelResume.pdf`;
    }
    // const download = () => {
    //     const pdfUrl = "AdityaPatelResume.pdf";
    //     const link = document.createElement("a");
    //     link.href = pdfUrl;
    //     link.download = "AdityaPatelResume.pdf"; // specify the filename
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    let classes = 'p-1.5 hover:shadow border-[var(--primary-color)] border-2 px-5 rounded-full lg:text-xl text-sm font-bold button';
    return (
        <div className='max-w-[490px] mx-auto flex flex-wrap justify-center gap-x-2.5 gap-y-2 w-[100%] m-2 lg:px-[3%] py-3'>
            <a href='#about' className={classes}>About</a>
            <button  onClick={redirect} className={classes}>Resume</button>
            <a href='#experience' className={classes}>Experience</a>
            <a href='#projects' className={classes}>Projects</a>
            {/*<a href='#interests' className={classes}>Interests</a>*/}
        </div>
    );
}
export default Links;