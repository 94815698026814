import { useState } from 'react';

let timeoutID = 0;

function Uneditable({inputValue}){
    const [val, setVal] = useState(String(inputValue));

    let getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    let handleEdit = (event) => {
        setVal(event.target.value)
        clearTimeout(timeoutID);
        timeoutID = setTimeout(deleteAndWrite, 1000, event.target.value);
    };
    let deleteAndWrite = (curVal) => {
        if (curVal !== inputValue) {
            let count = 0
            for (let i = 0; i < curVal.length; i++) {
                if (inputValue.charAt(i) === curVal.charAt(i)) {
                    count++
                } else {
                    break
                }
            }
            if (count < curVal.length) {
                setVal(curVal.slice(0, curVal.length - 1));
                timeoutID = setTimeout(deleteAndWrite, getRandomNumber(1,100), curVal.slice(0, curVal.length - 1)); // Adjust the delay here (in milliseconds)
            } else {
                setVal(inputValue.slice(0, curVal.length + 1))
                timeoutID = setTimeout(deleteAndWrite, getRandomNumber(50,400), inputValue.slice(0, curVal.length + 1)); // Adjust the delay here (in milliseconds)
            }
        }
    }

    return (
        <div>
            <input
                className='text-input w-[100%] lg:w-[42.5vw] lg:text-5xl text-3xl'
                onChange={handleEdit}
                value={val}
            />
        </div>
    );
}
export default Uneditable;